<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <div class="form-section-header">
                <p>{{ language.journeySet }}</p>
            </div>
            <ion-item lines="full" mode="md">
                <ion-label>
                    <h2>{{ journeySet.title }}</h2>
                </ion-label>
                <div class="icon-container" @click="editInfo"><ion-icon :icon="createOutline" class="edit-icon"></ion-icon></div>
            </ion-item>
            <div class="form-section-header">
                <p>{{ language.journeys }}</p>
                <span class="toggle" v-if="journeySet.journeys?.length > 1" :class="{active: !reorderDisabled }" @click="reorderDisabled = !reorderDisabled">{{ language.reorder }}</span>
            </div>
            <ion-reorder-group :disabled="reorderDisabled" @ionItemReorder="handleReorder($event)">
                <ion-item lines="full" mode="md" v-for="journey of journeySet.journeys" :key="journey.id">
                    <ion-label @click="editJourney(journey.id)">
                        <h2>{{ journey.title }}</h2>
                        <p v-for="scripture of journey.scriptureSelections" :key="scripture.id">{{ scripture.scriptureReference }}</p>
                    </ion-label>
                    <ion-reorder mode="ios" v-if="!reorderDisabled"></ion-reorder>
                    <div v-else class="icon-container" @click.stop="presentOptions(journey)"><ion-icon :icon="ellipsisVertical" class="edit-forward"></ion-icon></div>
                </ion-item>
            </ion-reorder-group>
            <h5 v-if="journeySet.journeys?.length === 0" class="ion-padding">
                {{ language.noJourneysYet }}
            </h5>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {
    alertController,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonReorderGroup,
    IonReorder
} from '@ionic/vue';
import {execute} from "@/mixins/LoadingMixin";
import {computed, defineComponent, ref} from "vue";
import {createOutline,  ellipsisVertical} from 'ionicons/icons';
import {getJourneySet} from "@/services/JourneySetService";
import {JourneySet} from "@/models/JourneySetModel";
import router from "@/router";
import {openSelectImageModal} from "@/services/SelectModalService";
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {Image} from "@/models/ImageModel";
import FixedFooter from "@/components/FixedFooter.vue";
import {presentNativeAlert} from "@/services/Notify";
import {Journey} from "@/models/JourneyModel";
import {deleteJourney, updateJourneys} from "@/services/JourneyService";
import {ActionSheetBuilder} from "@/mixins/ActionSheetBuilder";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {useStore} from "vuex";

export default defineComponent({
    name: 'SetJourneySetInfo',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonIcon,
        IonReorderGroup,
        IonReorder,
        FixedFooter
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        }
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeySetCreation);
        await execute(async () => {
            this.journeySet = await getJourneySet(this.journeySetId);
        }, this.language.errors.gettingJourneySets);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const journeySet = ref({} as JourneySet);
        const selectedIcon = ref({} as Image);
        const reorderDisabled = ref(true);

        const editInfo = () => {
            router.push({ name: 'journeySetInfo', params: { journeySetId: props.journeySetId }});
        }
        
        const editJourney = (journeyId: string) => {
            router.push({ name: 'journeyTitle', params: { journeySetId: props.journeySetId, journeyId: journeyId }});
        }

        const openImageSelectModal = async () => {
            const imageSelect = await openSelectImageModal(IMAGE_GROUP_TYPES.get(ImageGroupId.JourneySetIcon) || '', ImageGroupId.JourneySetIcon);
            imageSelect.onWillDismiss().then(({ data }: any) => {
                journeySet.value.imageId = data.id;
                selectedIcon.value = data;
            });
        }

        const removeImg = () => {
            journeySet.value.imageId = '';
            selectedIcon.value = {} as Image;
        }

        const handleReorder = (event: CustomEvent) => {
            journeySet.value.journeys = event.detail.complete(journeySet.value.journeys);
            for (let i = 1; i <= journeySet.value.journeys.length; i++) {
                journeySet.value.journeys[i-1].displayOrder = i;
            }
            execute(async () => {
                await updateJourneys(journeySet.value.journeys);
            }, language.value.errors.reorderingJourneys)
        }
        
        const confirmDeleteJourney = (journey: Journey) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.deleteJourneyQuestion,
                message: language.value.verifyDeleteJourneyQuestion,
                buttons: [
                    {
                        text: language.value.cancel,
                        role: 'cancel',
                        handler: () => {
                            alertController.dismiss();
                        },
                    },
                    {
                        text: 'Delete',
                        handler: async () => {
                            await alertController.dismiss();
                            await execute(async () => {
                                await deleteJourney(journey.id);
                                journeySet.value = await getJourneySet(props.journeySetId);
                            }, language.value.errors.deletingJourney);
                        },
                    },
                ]
            });
        }

        const presentOptions = async (journey: Journey) => {
            const actionSheetBuilder = new ActionSheetBuilder(journey.title)
                .addEdit(() => editJourney(journey.id))
                .addDelete(() => confirmDeleteJourney(journey));
            const actionSheet = await actionSheetBuilder.create();
            await actionSheet.present();
        }

        return {
            store,
            language,
            journeySet,
            reorderDisabled,
            createOutline,
            editInfo,
            openImageSelectModal,
            selectedIcon,
            removeImg,
            ellipsisVertical,
            handleReorder,
            presentOptions,
            editJourney,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, async () => { await router.push({ name: 'journeyTitle'}); }, language.value.addJourney)
                .create()
            )
        }
    }
});
</script>

<style scoped>
.ltr h5 {
    margin-left: 26px;
    padding-left: 0;
}

.rtl h5 {
    margin-right: 26px;
    padding-right: 0;
}

p {
    display: inline-block;
}

ion-label p {
    display: block;
}
</style>